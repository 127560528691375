<style lang="scss">
.mantel {
  .tabelle-box {
    .head-row {
      [class*='col-'] {
        text-transform: none !important;
      }
    }
    .col-12 {
      @media (max-width: 992px) {
        text-align: center !important;
      }
    }
  }
  h3 {
    margin-top: 0;
  }
  .tab-group-container {
    margin: 50px 0;
    .title-container {
      .title {
        &:hover {
          background: #eee;
        }
      }
      .active {
        &:hover {
          background: $blue-darker;
        }
      }
    }
  }
  .info-box {
    text-align: center;
    a {
      display: inline-block;
      padding: 10px;
      margin-top: 10px;
      &:before {
        content: '';
        display: block;
        width: 100px;
        height: 3px;
        background: #eee;
        margin: 10px auto;
      }
      &:hover {
        color: $blue !important;
      }
    }
  }
}
</style>

<template>
  <div class="mantel content container">
    <div class="row">
      <div class="col-lg-12">
        <h1>Ihr Mantel um die Kleine Zeitung.</h1>
        <h2>Kommunikation auf den ersten Blick.</h2>

        <ul class="content-ul">
          <li><strong>Auffälliger geht´s nicht!</strong> Ihre Werbebotschaft wird von unseren Leserinnen und Lesern als Erstes gesehen.</li>
          <li><strong>Exklusivität.</strong> Ihre Werbeform erscheint auffällig mit Exklusivplatzierung am gewünschten Tag.</li>
          <li><strong>Spielraum.</strong> Nutzen Sie die Möglichkeit einer Produktpräsentation auf 2 oder 4 Seiten.</li>
        </ul>
      </div>
    </div>

    <div class="row justify-content-center hinweis-box  margin-tb-m ">
      <div class="col-lg-2 bg-left align-self-center"><span class="material-icons">lightbulb</span></div>
      <div class="col-lg-10 bg-right align-self-center">
        <p>Sie haben mehr zu sagen? Wie wäre es mit einer Beilage oder einem Mitdruck? <router-link :to="{ name: 'Beilagen' }" class="content-link">Mehr zu Beilagen</router-link></p>
      </div>
    </div>
    <TabGroup>
      <Tab tabid="mantel-tab1" :title="'Mantel Zeitungspapier 42,5 g'" active>
        <div class="row justify-content-center align-self-center">
          <div class="col-lg-6 justify-content-center align-self-center">
            <img src="@/assets/img/img-content/beilagensonderwerbeformen/KLZ-Mantel_Sparkasse.jpg" alt="Mantel" class="img-fluid img-s-75 img-center shadow" />
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="tabelle-box">
              <div class="headline"><strong>Steiermark</strong><span>Mantel Zeitungspapier 42,5 g</span></div>
              <div class="row head-row hide-small">
                <div class="col-lg-4 text-align-left">Umfang</div>
                <div class="col-lg-4 text-align-right">MO - FR</div>
                <div class="col-lg-4 text-align-right">SA - SO</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left">2-seitig</div>
                <div class="col-6 text-align-left visible-small">MO - FR</div>
                <div class="col-lg-4 col-6 text-align-right">69.611,60</div>
                <div class="col-6 text-align-left visible-small">SA - SO</div>
                <div class="col-lg-4 col-6 text-align-right">73.092,18</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left">4-seitig</div>
                <div class="col-6 text-align-left visible-small">MO - FR</div>
                <div class="col-lg-4 col-6 text-align-right">78.309,28</div>
                <div class="col-6 text-align-left visible-small">SA - SO</div>
                <div class="col-lg-4 col-6 text-align-right">82.224,74</div>
              </div>
            </div>

            <div class="tabelle-box">
              <div class="headline"><strong>Kärnten </strong><span>Mantel Zeitungspapier 42,5 g</span></div>
              <div class="row head-row hide-small">
                <div class="col-lg-4 text-align-left">Umfang</div>
                <div class="col-lg-4 text-align-right">MO - FR</div>
                <div class="col-lg-4 text-align-right">SA - SO</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left">2-seitig</div>
                <div class="col-6 text-align-left visible-small">MO - FR</div>
                <div class="col-lg-4 col-6 text-align-right">34.805,80</div>
                <div class="col-6 text-align-left visible-small">SA - SO</div>
                <div class="col-lg-4 col-6 text-align-right">36.546,09</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left">4-seitig</div>
                <div class="col-6 text-align-left visible-small">MO - FR</div>
                <div class="col-lg-4 col-6 text-align-right">39.154,64</div>
                <div class="col-6 text-align-left visible-small">SA - SO</div>
                <div class="col-lg-4 col-6 text-align-right">41.112,37</div>
              </div>
            </div>

            <div class="tabelle-box">
              <div class="headline"><strong>Kombination</strong><span>Mantel Zeitungspapier 42,5 g</span></div>
              <div class="row head-row hide-small">
                <div class="col-lg-4 text-align-left">Umfang</div>
                <div class="col-lg-4 text-align-right">MO - FR</div>
                <div class="col-lg-4 text-align-right">SA - SO</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left">2-seitig</div>
                <div class="col-6 text-align-left visible-small">MO - FR</div>
                <div class="col-lg-4 col-6 text-align-right">102.370,00</div>
                <div class="col-6 text-align-left visible-small">SA - SO</div>
                <div class="col-lg-4 col-6 text-align-right">107.488,50</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left">4-seitig</div>
                <div class="col-6 text-align-left visible-small">MO - FR</div>
                <div class="col-lg-4 col-6 text-align-right">115.160,70</div>
                <div class="col-6 text-align-left visible-small">SA - SO</div>
                <div class="col-lg-4 col-6 text-align-right">120.918,74</div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-txt-preis">Die Preise verstehen sich in Euro exklusive 5 % Werbeabgabe und 20 % USt.</div>
        <div class="btn-std btn-bg-blue-dark color-fff text-center margin-tb-s"><router-link :to="{ name: 'Spezifikationen' }">Informationen zu den Spezifikationen </router-link></div>
      </Tab>
      <!--<Tab tabid="mantel-tab2" :title="'Mantel aufgebessertes Zeitungspapier 80 g'">
        <div class="tabelle-box">
          <div class="headline">
            Druckkosten
            <span class="info-txt">Mantel aufgebessertes Zeitungspapier 80 g<br />Die Kosten für diesen Mantel setzen sich aus Druck- und Verarbeitungskosten zusammen. </span>
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3 text-align-left">Stück</div>
            <div class="col-lg-3 text-align-right">Seiten 4 / Gewicht 11 g</div>
            <div class="col-lg-3 text-align-right">Seiten 8 / Gewicht 22 g</div>
            <div class="col-lg-3 text-align-right">Seiten 12 / Gewicht 32 g</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Stück</div>
            <div class="col-lg-3 col-6 text-align-left text-right-small">10.000</div>
            <div class="col-6 text-align-left visible-small">Seiten 4 / Gewicht 11 g</div>
            <div class="col-lg-3 col-6 text-align-right">1.669,80</div>
            <div class="col-6 text-align-left visible-small">Seiten 8 / Gewicht 22 g</div>
            <div class="col-lg-3 col-6 text-align-right">1.896,02</div>
            <div class="col-6 text-align-left visible-small">Seiten 12 / Gewicht 32 g</div>
            <div class="col-lg-3 col-6 text-align-right">2.088,42</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Stück</div>
            <div class="col-lg-3 col-6 text-align-left text-right-small">20.000</div>
            <div class="col-6 text-align-left visible-small">Seiten 4 / Gewicht 11 g</div>
            <div class="col-lg-3 col-6 text-align-right">2.132,63</div>
            <div class="col-6 text-align-left visible-small">Seiten 8 / Gewicht 22 g</div>
            <div class="col-lg-3 col-6 text-align-right">2.496,90</div>
            <div class="col-6 text-align-left visible-small">Seiten 12 / Gewicht 32 g</div>
            <div class="col-lg-3 col-6 text-align-right">2.860,83</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Stück</div>
            <div class="col-lg-3 col-6 text-align-left text-right-small">30.000</div>
            <div class="col-6 text-align-left visible-small">Seiten 4 / Gewicht 11 g</div>
            <div class="col-lg-3 col-6 text-align-right">2.561,81</div>
            <div class="col-6 text-align-left visible-small">Seiten 8 / Gewicht 22 g</div>
            <div class="col-lg-3 col-6 text-align-right">3.097,78</div>
            <div class="col-6 text-align-left visible-small">Seiten 12 / Gewicht 32 g</div>
            <div class="col-lg-3 col-6 text-align-right">3.633,24</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Stück</div>
            <div class="col-lg-3 col-6 text-align-left text-right-small">40.000</div>
            <div class="col-6 text-align-left visible-small">Seiten 4 / Gewicht 11 g</div>
            <div class="col-lg-3 col-6 text-align-right">2.991,00</div>
            <div class="col-6 text-align-left visible-small">Seiten 8 / Gewicht 22 g</div>
            <div class="col-lg-3 col-6 text-align-right">3.698,66</div>
            <div class="col-6 text-align-left visible-small">Seiten 12 / Gewicht 32 g</div>
            <div class="col-lg-3 col-6 text-align-right">4.405,65</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Stück</div>
            <div class="col-lg-3 col-6 text-align-left text-right-small">50.000</div>
            <div class="col-6 text-align-left visible-small">Seiten 4 / Gewicht 11 g</div>
            <div class="col-lg-3 col-6 text-align-right">3.420,18</div>
            <div class="col-6 text-align-left visible-small">Seiten 8 / Gewicht 22 g</div>
            <div class="col-lg-3 col-6 text-align-right">4.299,54</div>
            <div class="col-6 text-align-left visible-small">Seiten 12 / Gewicht 32 g</div>
            <div class="col-lg-3 col-6 text-align-right">5.178,06</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Stück</div>
            <div class="col-lg-3 col-6 text-align-left text-right-small">100.000</div>
            <div class="col-6 text-align-left visible-small">Seiten 4 / Gewicht 11 g</div>
            <div class="col-lg-3 col-6 text-align-right">5.566,08</div>
            <div class="col-6 text-align-left visible-small">Seiten 8 / Gewicht 22 g</div>
            <div class="col-lg-3 col-6 text-align-right">7.303,93</div>
            <div class="col-6 text-align-left visible-small">Seiten 12 / Gewicht 32 g</div>
            <div class="col-lg-3 col-6 text-align-right">9.040,11</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Stück</div>
            <div class="col-lg-3 col-6 text-align-left text-right-small">150.000</div>
            <div class="col-6 text-align-left visible-small">Seiten 4 / Gewicht 11 g</div>
            <div class="col-lg-3 col-6 text-align-right">7.711,99</div>
            <div class="col-6 text-align-left visible-small">Seiten 8 / Gewicht 22 g</div>
            <div class="col-lg-3 col-6 text-align-right">10.308,32</div>
            <div class="col-6 text-align-left visible-small">Seiten 12 / Gewicht 32 g</div>
            <div class="col-lg-3 col-6 text-align-right">12.902,16</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Stück</div>
            <div class="col-lg-3 col-6 text-align-left text-right-small">200.000</div>
            <div class="col-6 text-align-left visible-small">Seiten 4 / Gewicht 11 g</div>
            <div class="col-lg-3 col-6 text-align-right">9.857,89</div>
            <div class="col-6 text-align-left visible-small">Seiten 8 / Gewicht 22 g</div>
            <div class="col-lg-3 col-6 text-align-right">13.312,72</div>
            <div class="col-6 text-align-left visible-small">Seiten 12 / Gewicht 32 g</div>
            <div class="col-lg-3 col-6 text-align-right">16.764,20</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Stück</div>
            <div class="col-lg-3 col-6 text-align-left text-right-small">250.000</div>
            <div class="col-6 text-align-left visible-small">Seiten 4 / Gewicht 11 g</div>
            <div class="col-lg-3 col-6 text-align-right">12.003,80</div>
            <div class="col-6 text-align-left visible-small">Seiten 8 / Gewicht 22 g</div>
            <div class="col-lg-3 col-6 text-align-right">16.317,11</div>
            <div class="col-6 text-align-left visible-small">Seiten 12 / Gewicht 32 g</div>
            <div class="col-lg-3 col-6 text-align-right">20.626,25</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Stück</div>
            <div class="col-lg-3 col-6 text-align-left text-right-small">300.000</div>
            <div class="col-6 text-align-left visible-small">Seiten 4 / Gewicht 11 g</div>
            <div class="col-lg-3 col-6 text-align-right">14.149,71</div>
            <div class="col-6 text-align-left visible-small">Seiten 8 / Gewicht 22 g</div>
            <div class="col-lg-3 col-6 text-align-right">19.321,51</div>
            <div class="col-6 text-align-left visible-small">Seiten 12 / Gewicht 32 g</div>
            <div class="col-lg-3 col-6 text-align-right">24.488,30</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Stück</div>
            <div class="col-lg-3 col-6 text-align-left text-right-small">weitere 1.000</div>
            <div class="col-6 text-align-left visible-small">Seiten 4 / Gewicht 11 g</div>
            <div class="col-lg-3 col-6 text-align-right">42,92</div>
            <div class="col-6 text-align-left visible-small">Seiten 8 / Gewicht 22 g</div>
            <div class="col-lg-3 col-6 text-align-right">60,09</div>
            <div class="col-6 text-align-left visible-small">Seiten 12 / Gewicht 32 g</div>
            <div class="col-lg-3 col-6 text-align-right">77,24</div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="tabelle-box">
              <div class="headline">
                Verarbeitungskosten
              </div>
              <div class="row head-row">
                <div class="col-lg-4 col-12 text-align-left">unter 80.000 Stück</div>
                <div class="col-lg-4 text-align-right hide-small">bis 20 g</div>
                <div class="col-lg-4 text-align-right hide-small">bis 40 g</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left">MO - FR</div>
                <div class="col-6 text-align-left visible-small">bis 20 g</div>
                <div class="col-lg-4 col-6 text-align-right">415,80</div>
                <div class="col-6 text-align-left visible-small">bis 40 g</div>
                <div class="col-lg-4 col-6 text-align-right">446,78</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left">SA - SO</div>
                <div class="col-6 text-align-left visible-small">bis 20 g</div>
                <div class="col-lg-4 col-6 text-align-right">433,65</div>
                <div class="col-6 text-align-left visible-small">bis 40 g</div>
                <div class="col-lg-4 col-6 text-align-right">466,03</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="tabelle-box">
              <div class="headline">
                Verarbeitungskosten
              </div>
              <div class="row head-row ">
                <div class="col-lg-4 col-12 text-align-left">über 80.000 Stück</div>
                <div class="col-lg-4 text-align-right hide-small">bis 20 g</div>
                <div class="col-lg-4 text-align-right hide-small">bis 40 g</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left">MO - FR</div>
                <div class="col-6 text-align-left visible-small">bis 20 g</div>
                <div class="col-lg-4 col-6 text-align-right">409,50</div>
                <div class="col-6 text-align-left visible-small">bis 40 g</div>
                <div class="col-lg-4 col-6 text-align-right">440,30</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left">SA - SO</div>
                <div class="col-6 text-align-left visible-small">bis 20 g</div>
                <div class="col-lg-4 col-6 text-align-right">427,35</div>
                <div class="col-6 text-align-left visible-small">bis 40 g</div>
                <div class="col-lg-4 col-6 text-align-right">459,20</div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-txt-preis">Die Preise verstehen in Euro per 1.000 Stück exklusive 5 % Werbeabgabe und 20 % USt.</div>
        <div class="info-box"><router-link :to="{ name: 'Spezifikationen' }">Informationen zu den Spezifikationen finden Sie hier.</router-link></div>
        <div class="row justify-content-center align-self-center margin-40-0">
          <div class="col-lg-6 ">
            <img src="@/assets/img/img-content/beilagensonderwerbeformen/KLZ-Mantel_Sparkasse.jpg" alt="Mantel" class="img-fluid" />
          </div>
        </div>
      </Tab>-->
      <Tab tabid="mantel-tab3" :title="'Mantel LWC-Papier 135 g'">
        <div class="row">
          <div class="col-lg-6 align-self-center">
            <img src="@/assets/img/img-content/beilagensonderwerbeformen/KLZ-Mantel_Sparkasse.jpg" alt="Mantel" class="img-fluid img-s-75 img-center shadow" />
          </div>
          <div class="col-lg-6 align-self-center">
            <div class="tabelle-box">
              <div class="headline">
                Druckkosten
                <span class="info-txt">Mantel LWC-Papier 135g<br />Die Kosten für diesen Mantel setzen sich aus Druck- und Verarbeitungskosten zusammen. </span>
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-4 text-align-left">Stück</div>
                <div class="col-lg-4 text-align-right">Preis</div>
                <div class="col-lg-4 text-align-right">+1.000 Stk.</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">5.000</div>
                <div class="col-6 text-align-left visible-small">Preis</div>
                <div class="col-lg-4 col-6 text-align-right">817,50</div>
                <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
                <div class="col-lg-4 col-8 text-align-right">104,37</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">10.000</div>
                <div class="col-6 text-align-left visible-small">Preis</div>
                <div class="col-lg-4 col-6 text-align-right">1.239,75</div>
                <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
                <div class="col-lg-4 col-8 text-align-right">93,58</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">20.000</div>
                <div class="col-6 text-align-left visible-small">Preis</div>
                <div class="col-lg-4 col-6 text-align-right">2.002,00</div>
                <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
                <div class="col-lg-4 col-8 text-align-right">84,98</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">30.000</div>
                <div class="col-6 text-align-left visible-small">Preis</div>
                <div class="col-lg-4 col-6 text-align-right">2.639,25</div>
                <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
                <div class="col-lg-4 col-8 text-align-right">81,89</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">40.000</div>
                <div class="col-6 text-align-left visible-small">Preis</div>
                <div class="col-lg-4 col-6 text-align-right">3.204,50</div>
                <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
                <div class="col-lg-4 col-8 text-align-right">78,66</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">50.000</div>
                <div class="col-6 text-align-left visible-small">Preis</div>
                <div class="col-lg-4 col-6 text-align-right">3.743,75</div>
                <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
                <div class="col-lg-4 col-8 text-align-right">75,64</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">100.000</div>
                <div class="col-6 text-align-left visible-small">Preis</div>
                <div class="col-lg-4 col-6 text-align-right">6.958,80</div>
                <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
                <div class="col-lg-4 col-8 text-align-right">72,61</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">200.000</div>
                <div class="col-6 text-align-left visible-small">Preis</div>
                <div class="col-lg-4 col-6 text-align-right">13.282,50</div>
                <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
                <div class="col-lg-4 col-8 text-align-right">69,59</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Stück</div>
                <div class="col-lg-4 col-6 text-align-left text-right-small">300.000</div>
                <div class="col-6 text-align-left visible-small">Preis</div>
                <div class="col-lg-4 col-6 text-align-right">19.722,50</div>
                <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
                <div class="col-lg-4 col-8 text-align-right">69,59</div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="tabelle-box">
              <div class="headline">
                Verarbeitungskosten
              </div>
              <div class="row head-row">
                <div class="col-lg-4 col-12 text-align-left">unter 80.000 Stück</div>
                <div class="col-lg-4 text-align-right hide-small">bis 20 g</div>
                <div class="col-lg-4 text-align-right hide-small">bis 40 g</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left">MO - FR</div>
                <div class="col-6 text-align-left visible-small">bis 20 g</div>
                <div class="col-lg-4 col-6 text-align-right">415,80</div>
                <div class="col-6 text-align-left visible-small">bis 40 g</div>
                <div class="col-lg-4 col-6 text-align-right">446,78</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left">SA - SO</div>
                <div class="col-6 text-align-left visible-small">bis 20 g</div>
                <div class="col-lg-4 col-6 text-align-right">433,65</div>
                <div class="col-6 text-align-left visible-small">bis 40 g</div>
                <div class="col-lg-4 col-6 text-align-right">466,03</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="tabelle-box">
              <div class="headline">
                Verarbeitungskosten
              </div>
              <div class="row head-row ">
                <div class="col-lg-4 col-12 text-align-left">über 80.000 Stück</div>
                <div class="col-lg-4 text-align-right hide-small">bis 20 g</div>
                <div class="col-lg-4 text-align-right hide-small">bis 40 g</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left">MO - FR</div>
                <div class="col-6 text-align-left visible-small">bis 20 g</div>
                <div class="col-lg-4 col-6 text-align-right">409,50</div>
                <div class="col-6 text-align-left visible-small">bis 40 g</div>
                <div class="col-lg-4 col-6 text-align-right">440,30</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left">SA - SO</div>
                <div class="col-6 text-align-left visible-small">bis 20 g</div>
                <div class="col-lg-4 col-6 text-align-right">427,35</div>
                <div class="col-6 text-align-left visible-small">bis 40 g</div>
                <div class="col-lg-4 col-6 text-align-right">459,20</div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-txt-preis">Die Preise verstehen in Euro per 1.000 Stück exklusive 5 % Werbeabgabe und 20 % USt.</div>

        <div class="btn-std btn-bg-blue-dark color-fff text-center margin-tb-s"><router-link :to="{ name: 'Spezifikationen' }">Informationen zu den Spezifikationen </router-link></div>
      </Tab>
    </TabGroup>

    <div class="row justify-content-center align-self-center bg-blue-light highlight-box margin-tb-m">
      <div class="col-sm-6 d-flex align-self-center">
        <p>Mit dem Druckkosten-Rechner können Sie den Preis Ihres Mantels sofort berechnen.</p>
      </div>
      <div class="col-sm-6 d-flex align-self-center justify-content-end">
        <div class="btn-std btn-bg-blue-dark">
          <router-link :to="{ name: 'calculator' }">Druckkosten-Rechner</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  name: 'Mantel',
  components: {
    //Modal,
    TabGroup,
    Tab,
  },
});
</script>
